import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <h1>Under construction...</h1>
    </div>
  );
}

export default App;
